import { i18n } from '@lingui/core'
import * as plurals from 'make-plural/plurals'
import * as constants from '@connections/constants'
import enCatalog from './en/messages'
import nlCatalog from './nl-be/messages'
import frCatalog from './fr-be/messages'

const {
    LANGUAGE_NL,
    LANGUAGE_NL_BE,
    LANGUAGE_FR,
    LANGUAGE_FR_BE,
    LANGUAGE_EN,
    DEFAULT_LANGUAGE_WEB,
} = constants

export const supportedLanguages = [{
    code: LANGUAGE_NL_BE,
    baseCode: LANGUAGE_NL,
    catalog: nlCatalog.messages,
}, {
    code: LANGUAGE_FR_BE,
    baseCode: LANGUAGE_FR,
    catalog: frCatalog.messages,
}, {
    code: LANGUAGE_EN,
    baseCode: LANGUAGE_EN,
    catalog: enCatalog.messages,
}]

export default function setupLocales(locale = DEFAULT_LANGUAGE_WEB) {
    i18n.load(supportedLanguages.reduce((acc, { code, catalog }) => ({
        ...acc,
        [code]: catalog
    }), {}))
    supportedLanguages.forEach(({ code, baseCode }) => {
        i18n.loadLocaleData(code, {
            plurals: plurals[baseCode]
        })
    })
    i18n.activate(locale)
    return i18n
}
